import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
	copyrightName = environment.organizationName.concat(' - ', environment.applicationName);
	year: number;

	constructor() {}

	ngOnInit() {
		this.year = new Date().getFullYear();
	}
}
