import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pips-supervisor',
  templateUrl: './pips-supervisor.component.html',
  styleUrls: ['./pips-supervisor.component.css']
})
export class PipsSupervisorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
