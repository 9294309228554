import { NgModule } from '@angular/core';
import { AppMenuComponent } from './sidebar/menu/menu.component';
import { AppSubMenuComponent } from './sidebar/menu/submenu/submenu.component';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule } from '@angular/forms';
import { ScrollPanelModule } from 'primeng/primeng';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@NgModule({
	imports: [ CommonModule, FormsModule, ScrollPanelModule, RouterModule ],
	declarations: [ AppMenuComponent, AppSubMenuComponent, TopbarComponent, SidebarComponent, FooterComponent ],
	exports: [ AppMenuComponent, AppSubMenuComponent, TopbarComponent, SidebarComponent, FooterComponent ],
	providers: []
})
export class LayoutModule {}
