import { Component, OnInit, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, Message } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
	selector: 'app-document-upload',
	templateUrl: './document-upload.component.html'
})
export class DocumentUploadComponent implements OnInit {
	@Input() key: string;
	@Input() uploadUrl: string;
	@Input() keyName: string = 'Key';
	@Input() binaryName: string = 'DocumentFile';

	messages: Message[] = [];

	constructor(
		private configService: ConfigService,
		private dialogRef: DynamicDialogRef,
		private authService: AuthenticationService,
		private config: DynamicDialogConfig
	) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.key = this.config.data !== undefined ? this.config.data.key : undefined;
		this.uploadUrl = this.config.data !== undefined ? this.config.data.uploadUrl : undefined;
		this.keyName =
			this.config.data !== undefined
				? 'keyName' in this.config.data ? this.config.data.keyName : 'Key'
				: undefined;
		this.binaryName =
			this.config.data !== undefined
				? 'binaryName' in this.config.data ? this.config.data.binaryName : 'DocumentFile'
				: undefined;

		if (
			this.key === undefined ||
			this.uploadUrl === undefined ||
			this.binaryName === undefined ||
			this.keyName === undefined
		) {
			this.closeDialog(undefined);
		}
	}

	showView() {
		return this.key !== undefined && this.uploadUrl !== undefined;
	}

	beforeUpload(event: { xhr: XMLHttpRequest; formData: FormData }) {
		event.formData.append(this.keyName, this.key);
	}

	beforeSend(event: { xhr: XMLHttpRequest; formData: FormData }) {
		event.xhr.setRequestHeader('Authorization', 'Bearer ' + this.authService.getAccessToken());
	}

	afterUpload(event: { xhr: XMLHttpRequest; files: [] }) {
		const response = JSON.parse(event.xhr.response);

		this.closeDialog(response);
	}

	handleError(event: { xhr: XMLHttpRequest; files: [] }) {
		if (''.concat(event.xhr.response).length > 1) {
			const error = JSON.parse(event.xhr.response);
			if ('errorMessage' in error) {
				this.setMessage({
					severity: 'error',
					detail: error['errorMessage']
				});
			}
		} else {
			this.setMessage({
				severity: 'error',
				detail: event.xhr.statusText
			});
		}
	}

	closeDialog(result: any) {
		this.dialogRef.close(result);
	}
}
