import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AccountModule } from './account/account.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './guards/auth-guard.service';
import { RoleGuardService } from './guards/role-guard.service';
import { ConfigService } from './services/config.service';
import { ProfileService } from './services/profile.service';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared/shared.module';
import { CountryGuardService } from './guards/country-guard.service';
import { PipsSupervisorComponent } from './pips/pips-supervisor/pips-supervisor.component';
import { PipsEmployeeComponent } from './pips/pips-employee/pips-employee.component';

@NgModule({
	imports: [
		BrowserModule,
		HttpClientModule,
		AccountModule,
		CoreModule,
		SharedModule,
		OAuthModule.forRoot({
			resourceServer: {
				sendAccessToken: true,
				allowedUrls: environment.allowedUrls
			}
		}),
		AppRoutingModule,
		BrowserAnimationsModule
	],
	declarations: [ AppComponent, PipsSupervisorComponent, PipsEmployeeComponent ],
	providers: [
		AuthenticationService,
		ConfigService,
		ProfileService,
		AuthGuardService,
		RoleGuardService,
		CountryGuardService,
		{ provide: OAuthStorage, useValue: localStorage, },
		
		
		
	],
	bootstrap: [ AppComponent ]
})
export class AppModule {}
