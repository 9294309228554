import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class CountryGuardService implements CanActivate {
	constructor(private authService: AuthenticationService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const noCountry = this.authService.getCountries().length === 0;
		const oneCountry = this.authService.getCountries().length === 1;
		const manyCountries = this.authService.getCountries().length > 1;

		if (noCountry) {
			return this.router.createUrlTree([ '/no-country' ]);
		}
		if (oneCountry) {
			this.authService.setCountry(this.authService.getCountries()[0]);
		}
		if (manyCountries && this.authService.selectedCountry === undefined) {
			return this.router.createUrlTree([ '/choose-country' ]);
		}

		return true;
	}
}
