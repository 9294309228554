import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class ConfigService {
	apiBaseUrl: string;

	constructor(private authService: AuthenticationService) {
		this.apiBaseUrl = this.authService.getApiBaseUrl();
	}

	organizationName = environment.organizationName;
	authApiBaseUrl = environment.authApiBaseUrl;
	confirmEmailCallbackUrl = environment.confirmEmailCallbackUrl;
	resetPasswordCallbackUrl = environment.resetPasswordCallbackUrl;
}
