import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { EmployeeProfile } from '../../models/employee-profile.model';
import { EmployeeService } from '../../services/employee.service';

@Component({
	selector: 'app-profile-edit',
	templateUrl: './profile-edit.component.html'
})
export class ProfileEditComponent implements OnInit {
	profile: EmployeeProfile;
	form: FormGroup;
	showForm = true;

	messages: Message[] = [];

	constructor(private employeeService: EmployeeService, private router: Router) {}

	getReligions() {
		return this.employeeService.religions;
	}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	setupForm() {
		this.form = new FormGroup({
			address: new FormControl(this.profile.address),
			address_2: new FormControl(this.profile.address_2),
			post_Code: new FormControl(this.profile.post_Code),
			city: new FormControl(this.profile.city),
			mobile_Phone_No: new FormControl(this.profile.mobile_Phone_No),
			phone_No: new FormControl(this.profile.phone_No),
			home_Phone_No: new FormControl(this.profile.home_Phone_No),
			e_Mail: new FormControl(this.profile.e_Mail),
			religion: new FormControl(this.profile.religion),
			no: new FormControl(this.profile.no)
		});
	}

	ngOnInit() {
		this.profile = history.state.data;
		if (this.profile === undefined) {
			this.employeeService.getEmployeeProfile().subscribe(
				(profile) => {
					this.profile = profile;
					this.setupForm();
				},
				(error) => {
					if ('errorMessage' in error.error) {
						this.setMessage({
							severity: 'error',
							detail: error.error['errorMessage']
						});
					}
				}
			);
		} else {
			this.setupForm();
		}
	}

	showView() {
		return this.form !== undefined && this.getReligions() !== undefined && this.showForm;
	}

	onSaveForm() {
		this.showForm = false;
		const record = this.form.value;

		this.employeeService.updateEmployeeProfile(record).subscribe(
			(item) => {
				//navigate to detail
				this.router.navigate([ '/core/dashboard' ], {
					state: {
						data: item
					}
				});
			},
			(error) => {
				this.showForm = true;
				if ('errorMessage' in error.error) {
					this.setMessage({
						severity: 'error',
						detail: error.error['errorMessage']
					});
				}
			}
		);
	}
}
