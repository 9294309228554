import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	showForm: boolean = true;

	messages: Message[] = [];

	constructor(private authService: AuthenticationService, private router: Router) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.loginForm = new FormGroup({
			username: new FormControl(null, Validators.required),
			password: new FormControl(null, Validators.required)
		});
		if (this.authService.isAuthenticated()) {
			return this.router.navigate([ '/core' ]);
		}
	}

	onLogin() {
		this.showForm = false;
		const username = this.loginForm.get('username').value;
		const password = this.loginForm.get('password').value;
		this.authService
			.login(username, password)
			.then((userProfile) => {
				this.authService.userProfile = userProfile;
				//Redirect to core module
				return this.router.navigate([ '/core' ]);
			})
			.catch((result) => {
				this.showForm = true;
				const error_desc = result.error.error_description;
				this.setMessage({
					severity: 'error',
					detail: error_desc
				});
			});
	}
}
