import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountComponent } from './account.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NoCountryComponent } from './no-country/no-country.component';
import { ChooseCountryComponent } from './choose-country/choose-country.component';
const routes: Routes = [
	{
		path: '',
		component: AccountComponent,
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'login' },
			{
				path: 'unauthorized',
				component: UnauthorizedComponent,
				canActivate: [ AuthGuardService ]
			},
			{
				path: 'no-country',
				component: NoCountryComponent,
				canActivate: [ AuthGuardService ]
			},
			{
				path: 'choose-country',
				component: ChooseCountryComponent,
				canActivate: [ AuthGuardService ]
			},
			{ path: 'login', component: LoginComponent },
			{ path: 'forgot-password', component: ForgotPasswordComponent },
			{ path: 'reset-password', component: ResetPasswordComponent },
			{ path: 'confirm-email', component: ConfirmEmailComponent }
		]
	}
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
	declarations: [],
	
})
export class AccountRoutingModule {}
