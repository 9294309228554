import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { SelectItem } from 'primeng/api';

@Injectable()
export class DimensionService {
	budgetCodes: SelectItem[];
	costCenterCodes: SelectItem[];
	activityCodes: SelectItem[];
	costUnitCodes: SelectItem[];
	typeCodes: SelectItem[];
	countryCodes: SelectItem[];
	funderCodes: SelectItem[];

	currencyCodes: SelectItem[];

	constructor(private httpClient: HttpClient, private configService: ConfigService) {
		this.getBudgetCodes().subscribe((items) => {
			this.budgetCodes = items;
		});
		this.getCostCenterCodes().subscribe((items) => {
			this.costCenterCodes = items;
		});
		this.getActivityCodes().subscribe((items) => {
			this.activityCodes = items;
		});
		this.getCostUnitCodes().subscribe((items) => {
			this.costUnitCodes = items;
		});
		this.getTypeCodes().subscribe((items) => {
			this.typeCodes = items;
		});
		this.getCountryCodes().subscribe((items) => {
			this.countryCodes = items;
		});
		this.getFunderCodes().subscribe((items) => {
			this.funderCodes = items;
		});
		this.getCurrencyCodes().subscribe((items) => {
			this.currencyCodes = items;
		});
	}

	getBudgetCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/BudgetCode')
		);
	}

	getCostCenterCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/CostCenterCode')
		);
	}

	getActivityCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/ActivityCode')
		);
	}

	getCostUnitCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/CostunitCode')
		);
	}

	getTypeCodes() {
		return this.httpClient.get<SelectItem[]>(this.configService.apiBaseUrl.concat('PurchaseRequisition/TypeCode'));
	}

	getCountryCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/CountryCode')
		);
	}

	getFunderCodes() {
		return this.httpClient.get<SelectItem[]>(
			this.configService.apiBaseUrl.concat('PurchaseRequisition/FundersCode')
		);
	}

	getCurrencyCodes() {
		return this.httpClient.get<SelectItem[]>(this.configService.apiBaseUrl.concat('PurchaseRequisition/Currency'));
	}
}
