import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-account',
	templateUrl: './account.component.html'
})
export class AccountComponent implements OnInit {
	showOverlayMenu: boolean = false;

	toggleOverlayMenu() {
		this.showOverlayMenu = !this.showOverlayMenu;
	}

	constructor(public authService: AuthenticationService, private router: Router) {}

	ngOnInit() {}

	login() {
		this.toggleOverlayMenu();
		this.router.navigate([ '/login' ]);
	}

	logout() {
		this.toggleOverlayMenu();
		this.authService.logout();
		this.router.navigate([ '/login' ]);
	}
}
