import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { Approval } from '../models/approval.model';

@Injectable()
export class ApprovalService {
	constructor(private httpClient: HttpClient, private configService: ConfigService) {}

	getApprovals(documentNo: string) {
		return this.httpClient.get<Approval[]>(this.configService.apiBaseUrl.concat('Approvals'), {
			params: {
				documentNo: documentNo
			}
		});
	}
}
