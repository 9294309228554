import { EventEmitter, Injectable } from '@angular/core';
import { OAuthService, EventType } from 'angular-oauth2-oidc';
import { authConfig } from '../helpers/auth.config';
import { filter } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthenticationService {
	userProfile: any;
	logoutEvent: EventType = 'logout';
	selectedCountry: string;

	constructor(public oauthService: OAuthService) {
		this.oauthService.configure(authConfig);
		this.oauthService.loadDiscoveryDocument().then(() => {
			//Load profile if there's an access Token
			if (this.isAuthenticated()) {
				this.loadUserProfile().then((userProfile) => {
					this.userProfile = userProfile;
					//Set country
					if (this.getCountries().length === 1) {
						this.setCountry(this.getCountries()[0]);
					}
				});
			}
		});
		this.loggedOut().subscribe((status) => {
			if (status) {
				this.selectedCountry = undefined;
			}
		});
	}

	loadUserProfile() {
		return this.oauthService.loadUserProfile();
	}

	tokenReceived() {
		return this.oauthService.events.pipe(
			filter((event) => {
				return [ 'token_received' ].includes(event.type);
			})
		);
	}

	loggedOut() {
		return this.oauthService.events.pipe(
			filter((event) => {
				return this.logoutEvent == event.type;
			})
		);
	}

	isAuthenticated() {
		return this.oauthService.hasValidAccessToken();
	}

	login(username: string, password: string) {
		return this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password);
	}

	logout() {
		this.oauthService.logOut(true);
	}

	getAccessToken() {
		return this.oauthService.getAccessToken();
	}

	getClaims() {
		return this.oauthService.getIdentityClaims();
	}

	getUserProfile() {
		return this.userProfile;
	}

	hasPermission(permission: string) {
		const permissions: string[] = this.getClaims()['Permission'];
		return permissions.includes(permission);
	}

	getCountries(): string[] {
		//return this.getClaims()['country'] === null ? [] : this.getClaims()['country'].split(',');
		return [ 'Kenya' ];
	}

	setCountry(country: string) {
		this.selectedCountry = country;
	}

	getApiBaseUrl() {
		return this.selectedCountry === 'Kenya'
			? environment.kenyaApiBaseUrl
			: this.selectedCountry === 'USA' ? environment.usaApiBaseUrl : '';
	}

	getProfileNo(): string {
		return this.getClaims()['profileNo'];
	}

	getEmployeeNo(): string {
		return this.getClaims()['employeeNo'];
	}

	getUserEmail(): string {
		return this.getClaims()['userEmail'];
	}

	passwordCorrectSubject = new Subject<boolean>();

	setPasswordCorrectness(result: boolean) {
		this.passwordCorrectSubject.next(result);
	}
}
