import { Component, Input, OnInit } from '@angular/core';
import { MenuItem, ScrollPanel } from 'primeng/primeng';

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html'
})
export class AppMenuComponent implements OnInit {
	@Input() scrollerViewChild: ScrollPanel;

	model: MenuItem[];

	ngOnInit() {
		this.model = [
			{
				label: 'Dashboard',
				icon: 'fa fa-fw fa-home',
				routerLink: ['/core/dashboard']
			},
			{
				label: 'Human Resource',
				icon: 'fa fa-fw fa-folder',
				expanded: true,
				items: [
					{
						label: 'Leave Applications',
						icon: 'fa fa-fw fa-file-text-o',
						routerLink: ['/core/leave-applications']
					},
					{
						label: 'Leave Planner',
						icon: 'fa fa-fw fa-list-ol',
						routerLink: ['/core/leave-applications/leave-plans']
					},
					{
						label: 'Out of Station Request',
						icon: 'fa fa-fw fa-list-ol',
						routerLink: ['/core/leave-applications/duty-leave']
					},
					{
						label: 'Timesheets',
						icon: 'fa fa-fw fa-clock-o',
						routerLink: ['/core/timesheets']
					},
					{
						label: 'Transport Requests',
						icon: 'fa fa-fw fa-bus',
						routerLink: ['/core/transport']
					},
					{
						label: 'Appraisals',
						icon: 'fa fa-fw fa-folder',
						items: [
							{
								label: 'My Appraisals',
								icon: 'fa fa-fw fa-book',
								items: [
									{
										label: 'Target Setting',
										icon: 'fa fa-fw fa-dot-circle-o',
										routerLink: ['/core/appraisals/targets']
									},
									{
										label: 'Mid Year Evaluations',
										icon: 'fa fa-fw fa-calendar-minus-o',
										routerLink: ['/core/appraisals/mid-year']
									},
									{
										label: 'End Year Evaluations',
										icon: 'fa fa-fw fa-calendar-plus-o',
										routerLink: ['/core/appraisals/end-year']
									},
									{
										label: 'Closed Evaluations',
										icon: 'fa fa-fw fa-lock',
										routerLink: ['/core/appraisals/closed']
									},
									{
										label: 'Quarterly Reviews',
										icon: 'fa fa-fw fa-line-chart',
										routerLink: ['/core/appraisals/quarterly-review']
									}
								]
							},
							{
								label: 'My Appraisees',
								icon: 'fa fa-fw fa-users',
								items: [
									{
										label: 'Target Review',
										icon: 'fa fa-fw fa-dot-circle-o',
										routerLink: ['/core/appraisals/reviews']
									},
									{
										label: 'Mid Year Evaluation',
										icon: 'fa fa-fw fa-calendar-minus-o',
										routerLink: ['/core/appraisals/mid-year/supervisor']
									},
									{
										label: 'End Year Evaluation',
										icon: 'fa fa-fw fa-calendar-plus-o',
										routerLink: ['/core/appraisals/end-year/supervisor']
									},
									{
										label: 'Closed Evaluations',
										icon: 'fa fa-fw fa-lock',
										routerLink: ['/core/appraisals/closed/supervisor']
									},
									{
										label: 'Quarterly Reviews',
										icon: 'fa fa-fw fa-line-chart',
										routerLink: ['/core/appraisals/quarterly-review/supervisor']
									}
									// {
									// 	label: '360 Degree Appraisal',
									// 	icon: 'fa fa-fw fa-circle-o-notch',
									// 	routerLink: [ '/core/appraisals/three-sixty' ]
									// }
								]
							}
						]
					},
					{
						label: 'Intern/Trainee Evaluation',
						icon: 'fa fa-fw fa-folder',
						items: [
							{
								label: 'Intern/Trainee',
								icon: 'fa fa-fw fa-book',
								routerLink: ['/core/graduate-evaluation/employee']
								
							},
							{
								label: 'Supervisor',
								icon: 'fa fa-fw fa-book',
								routerLink: ['/core/graduate-evaluation/supervisor']
								
							}						
						]
					},
					
					{
						label: 'PIPs',
						icon: 'fa fa-fw fa-shopping-cart',
						items: [
							{
								label: 'Supervisor',
								icon: 'fa fa-fw fa-tags',
								routerLink: ['/core/pip/supervisor']
							},
							{
								label: 'Employee ',
								icon: 'fa fa-fw fa-tags',
								routerLink: ['/core/pip/employee']
							}
						]
					},
					{
						label: 'Performance Potential',
						icon: 'fa fa fa-binoculars',
						routerLink: ['/core/ppe']
					},

					{
						label: 'Grievances',
						icon: 'fa fa-fw fa-ticket',
						routerLink: ['/core/grievances']
					},
					{
						label: 'Accidents & Incidents',
						icon: 'fa fa-fw fa-fire',
						routerLink: ['/core/accidents']
					},
					{
						label: 'Training Applications',
						icon: 'fa fa-fw fa-book',
						routerLink: ['/core/training-applications']
					},
					{
						label: 'Probation Review',
						icon: 'fa fa-fw fa-files-o',
						items: [
							{
								label: 'My Review',
								icon: 'fa fa-fw fa-hand-paper-o',
								routerLink: ['/core/probation']
							},
							{
								label: 'Supervisor Review',
								icon: 'fa fa-fw fa-user-o',
								routerLink: ['/core/probation/list/supervisor']
							}
						]
					},
					{
						label: 'Employee Requisition',
						icon: 'fa fa-fw fa-vcard-o',
						routerLink: ['/core/employee-requisitions']
					},
					{
						label: 'Employee Exit',
						icon: 'fa fa-fw fa-hourglass-end',
						items: [
							{
								label: 'My Exit',
								icon: 'fa fa-fw fa-hand-peace-o',
								routerLink: ['/core/employee-requisitions/exit']
							},
							{
								label: 'Exit Clearance',
								icon: 'fa fa-fw fa-bars',
								routerLink: ['/core/employee-requisitions/clearance-lines']
							}
						]
					}
				]
			},
			{
				label: 'Procurement',
				icon: 'fa fa-fw fa-shopping-cart',
				items: [
					{
						label: 'Purchase Requisitions',
						icon: 'fa fa-fw fa-tags',
						routerLink: ['/core/purchase/stage2-list']
					},
					{
						label: 'Work Order',
						icon: 'fa fa-fw fa-tags',
						routerLink: ['/core/workorder/lso']
					}
				]
			},
			{
				label: 'Finance',
				icon: 'fa fa-fw fa-money',
				items: [
					{
						label: 'Travel Advances',
						icon: 'fa fa-fw fa-plane',
						items: [
							{
								label: 'Open Travel Advances',
								icon: 'fa fa-fw fa-plus-square-o',
								routerLink: ['/core/funds/travel-advance-list']
							},
							{
								label: 'Posted Travel Advances',
								icon: 'fa fa-fw fa-bars',
								routerLink: ['/core/funds/posted-travel-advances']
							}
						]
					},
					{
						label: 'Staff Advances',
						icon: 'fa fa-fw fa-odnoklassniki',
						items: [
							{
								label: 'Open Staff Advances',
								icon: 'fa fa-fw fa-plus-square-o',
								routerLink: ['/core/funds/staff-advance-list']
							},
							{
								label: 'Posted Staff Advances',
								icon: 'fa fa-fw fa-bars',
								routerLink: ['/core/funds/posted-staff-advances']
							}
						]
					},
					
					{
						label: 'Claims',
						icon: 'fa fa-fw fa-clipboard',
						routerLink: ['/core/funds/staff-claims']
					},
					{
						label: 'Posted Staff Claims',
						icon: 'fa fa-fw fa-clipboard',
						routerLink: ['/core/funds/posted-staff-claim']
					}
				]
			},
			{
				label: 'Reports & Downloads',
				icon: 'fa fa-fw fa-paperclip',
				items: [
					{
						label: 'P9 Form',
						icon: 'fa fa-fw fa-file-text-o',
						routerLink: ['/core/reports/p9form']
					},
					{
						label: 'Payslip',
						icon: 'fa fa-fw fa-file-text-o',
						routerLink: ['/core/reports/payslip']
					},
					{
						label: 'Downloads',
						icon: 'fa fa-fw fa-download',
						routerLink: ['/core/reports/downloads']
					}
				]
			}
		];
	}
}
