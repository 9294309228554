import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
	selector: 'app-confirm-email',
	templateUrl: './confirm-email.component.html'
})
export class ConfirmEmailComponent implements OnInit {
	messages: Message[] = [];
	email: string;
	token: string;

	showView = false;

	constructor(
		private profileService: ProfileService,
		private authService: AuthenticationService,
		private activatedRoute: ActivatedRoute,
		private router: Router
	) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.activatedRoute.queryParams.subscribe((params) => {
			this.email = params.email;
			this.token = params.token;
			this.profileService.confirmEmail(this.email, this.token).subscribe(
				(item) => {
					this.showView = true;
					this.setMessage({
						severity: 'info',
						detail: item.infoMessage
					});
				},
				(error) => {
					this.showView = true;
					if ('errorMessage' in error.error) {
						this.setMessage({
							severity: 'error',
							detail: error.error['errorMessage']
						});
					}
				}
			);
		});
	}

	onBackToLogin() {
		this.router.navigate([ '/login' ]);
	}
}
