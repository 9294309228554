import { NgModule } from '@angular/core';
import { CoreComponent } from './core.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutModule } from './layout/layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { DashboardService } from './services/dashboard.service';
import { LayoutService } from './services/layout.service';
import { EmployeeService } from './services/employee.service';
import {
	DropdownModule,
	FieldsetModule,
	MessageModule,
	MessagesModule,
	PanelModule,
	ToolbarModule
} from 'primeng/primeng';
import { DimensionService } from './services/dimension.service';
import { ReportService } from './services/report.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileEditComponent } from './dashboard/profile-edit/profile-edit.component';


@NgModule({
	imports: [
		CommonModule,
		ProgressSpinnerModule,
		TableModule,
		ProgressBarModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		DropdownModule,
		ToolbarModule,
		PanelModule,
		FieldsetModule,
		MessagesModule,
		MessageModule,
		LayoutModule,
		CoreRoutingModule
	],
	declarations: [ CoreComponent, DashboardComponent, ChangePasswordComponent, ProfileEditComponent],
	exports: [],
	providers: [ LayoutService, DashboardService, EmployeeService, DimensionService, ReportService ]
})
export class CoreModule {}
