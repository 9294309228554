import { Injectable } from '@angular/core';
import { ScrollPanel } from 'primeng/primeng';

@Injectable()
export class LayoutService {
	constructor() {}

	public menuInactiveDesktop: boolean;

	public menuActiveMobile: boolean;

	public profileActive: boolean;

	public topMenuActive: boolean;

	public topMenuLeaving: boolean;

	public menuClick: boolean;

	public topMenuButtonClick: boolean;

	toggleMenu(event: Event) {
		this.menuClick = true;
		if (this.isDesktop()) {
			this.menuInactiveDesktop = !this.menuInactiveDesktop;
			if (this.menuInactiveDesktop) {
				this.menuActiveMobile = false;
			}
		} else {
			this.menuActiveMobile = !this.menuActiveMobile;
			if (this.menuActiveMobile) {
				this.menuInactiveDesktop = false;
			}
		}

		if (this.topMenuActive) {
			this.hideTopMenu();
		}

		event.preventDefault();
	}

	toggleProfile(event: Event) {
		this.profileActive = !this.profileActive;
		event.preventDefault();
	}

	toggleTopMenu(event: Event) {
		this.topMenuButtonClick = true;
		this.menuActiveMobile = false;

		if (this.topMenuActive) {
			this.hideTopMenu();
		} else {
			this.topMenuActive = true;
		}

		event.preventDefault();
	}

	hideTopMenu() {
		this.topMenuLeaving = true;
		setTimeout(() => {
			this.topMenuActive = false;
			this.topMenuLeaving = false;
		}, 500);
	}

	onMenuClick(scrollerViewChild: ScrollPanel) {
		this.menuClick = true;

		setTimeout(() => {
			scrollerViewChild.moveBar();
		}, 500);
	}

	isDesktop() {
		return window.innerWidth > 1024;
	}

	onSearchClick() {
		this.topMenuButtonClick = true;
	}
}
