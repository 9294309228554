import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { ScrollPanel } from 'primeng/primeng';
import { LayoutService } from 'src/app/core/services/layout.service';

@Component({
	/* tslint:disable:component-selector */
	selector: '[app-submenu]',
	/* tslint:enable:component-selector */
	templateUrl: './submenu.component.html',
	animations: [
		trigger('children', [
			state(
				'hidden',
				style({
					height: '0px'
				})
			),
			state(
				'visible',
				style({
					height: '*'
				})
			),
			transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class AppSubMenuComponent {
	@Input() item: MenuItem;

	@Input() root: boolean;

	@Input() visible: boolean;

	@Input() scrollerViewChild: ScrollPanel;

	activeIndex: number;

	_parentActive: boolean;

	constructor(public layoutService: LayoutService) {}

	itemClick(event: Event, item: MenuItem, index: number) {
		if (item.disabled) {
			event.preventDefault();
			return;
		}

		this.activeIndex = this.activeIndex === index ? null : index;

		// execute command
		if (item.command) {
			item.command({ originalEvent: event, item: item });
		}

		// prevent hash change
		if (item.items || (!item.url && !item.routerLink)) {
			setTimeout(() => {
				this.scrollerViewChild.moveBar();
			}, 400);
			event.preventDefault();
		}

		if (!item.items) {
			this.layoutService.menuActiveMobile = false;
		}
	}

	isActive(index: number): boolean {
		return this.activeIndex === index;
	}

	@Input()
	get parentActive(): boolean {
		return this._parentActive;
	}

	set parentActive(val: boolean) {
		this._parentActive = val;

		if (!this._parentActive) {
			this.activeIndex = null;
		}
	}
}
