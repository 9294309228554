import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ScrollPanel } from 'primeng/primeng';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LayoutService } from '../../services/layout.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	animations: [
		trigger('submenu', [
			state(
				'hidden',
				style({
					height: '0px'
				})
			),
			state(
				'visible',
				style({
					height: '*'
				})
			),
			transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
			transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
		])
	]
})
export class SidebarComponent implements OnInit, AfterViewInit {
	constructor(
		public layoutService: LayoutService,
		private router: Router,
		private authService: AuthenticationService
	) {}

	@ViewChild('scroller') public scrollerViewChild: ScrollPanel;

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.scrollerViewChild.moveBar();
		}, 100);
	}

	profileLoaded() {
		return this.authService.userProfile !== null;
	}

	getUserName() {
		return this.authService.getClaims()['name'];
	}

	logout() {
		this.authService.logout();
		this.router.navigate([ '/login' ]);
	}

	ngOnInit() {}
}
