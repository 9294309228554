import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
	organizationName: string;
	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private configService: ConfigService
	) {}

	ngOnInit() {
		this.organizationName = this.configService.organizationName;
	}

	logout() {
		this.authService.logout();
		this.router.navigate([ '/login' ]);
	}
}
