import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html'
})
export class TopbarComponent implements OnInit {
	constructor(public layoutService: LayoutService, private authService: AuthenticationService) {}

	ngOnInit() {}

	getName() {
		return [ this.authService.getClaims()['firstName'], this.authService.getClaims()['lastName'] ].join(' ');
	}

	showEmail() {
		return this.authService.userProfile !== null;
	}
}
