import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RoleGuardService implements CanActivate {
	constructor(private authService: AuthenticationService, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
		const hasRequiredClaims = this.authService.getEmployeeNo().length > 1;
		if (!hasRequiredClaims) {
			return this.router.createUrlTree([ '/unauthorized' ]);
		}

		return true;
	}
}
