import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, Message } from 'primeng/api';
import { Approval } from '../models/approval.model';
import { ApprovalService } from '../services/approvals.service';

@Component({
	selector: 'app-approvals',
	templateUrl: './approvals.component.html'
})
export class ApprovalsComponent implements OnInit {
	messages: Message[] = [];
	documentNo: string = '';

	constructor(
		private approvalService: ApprovalService,
		private dialogRef: DynamicDialogRef,
		private config: DynamicDialogConfig
	) {}

	approvals: Approval[];

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.documentNo = this.config.data !== undefined ? this.config.data.documentNo : '';
		this.approvalService.getApprovals(this.documentNo).subscribe((items) => {
			this.approvals = items;
		});
	}

	showTable() {
		return this.approvals !== undefined;
	}

	closeDialog(result: any) {
		this.dialogRef.close(result);
	}
}
