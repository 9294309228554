import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {
	forgotPasswordForm: FormGroup;
	showForm: boolean = true;

	messages: Message[] = [];

	constructor(
		private profileService: ProfileService,
		private authService: AuthenticationService,
		private router: Router
	) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.forgotPasswordForm = new FormGroup({
			email: new FormControl(null, [ Validators.required, Validators.email ])
		});
		if (this.authService.isAuthenticated()) {
			return this.router.navigate([ '/core' ]);
		}
	}

	onSendPasswordLink() {
		this.showForm = false;
		const email = this.forgotPasswordForm.get('email').value;
		this.profileService.sendForgotPasswordLink(email).subscribe(
			(item) => {
				this.showForm = true;
				this.setMessage({
					severity: 'info',
					detail: item.infoMessage
				});
			},
			(error) => {
				this.showForm = true;
				if ('errorMessage' in error.error) {
					this.setMessage({
						severity: 'info',
						detail: error.error['errorMessage']
					});
				}
			}
		);
	}

	onBackToLogin() {
		this.router.navigate([ '/login' ]);
	}
}
