import { NgModule } from '@angular/core';
import { AccountComponent } from './account.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AccountRoutingModule } from './account-routing.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { LoginComponent } from './login/login.component';
import {
	InputTextModule,
	MessageModule,
	MessagesModule,
	ProgressSpinnerModule,
	DropdownModule,
	TooltipModule,
	MultiSelectModule
} from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmEmailComponent } from './confirm-email/confirm-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NoCountryComponent } from './no-country/no-country.component';
import { ChooseCountryComponent } from './choose-country/choose-country.component';

@NgModule({
	imports: [
		CommonModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		MessageModule,
		ProgressSpinnerModule,
		DropdownModule,
		TooltipModule,
		MessagesModule,
		MultiSelectModule,
		AccountRoutingModule,
		InputTextModule
	],
	declarations: [
		AccountComponent,
		UnauthorizedComponent,
		LoginComponent,
		ConfirmEmailComponent,
		ForgotPasswordComponent,
		ResetPasswordComponent,
		NoCountryComponent,
		ChooseCountryComponent
	],
	exports: [],
	providers: []
})
export class AccountModule {}
