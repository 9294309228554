// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
	production: false,
	organizationName: 'The AECF',
	applicationName: 'Employee Portal',
	kenyaApiBaseUrl: 'https://ess.aecfafrica.org/api/',
	usaApiBaseUrl: 'https://ess.aecfafrica.org/api/',
	authApiBaseUrl: 'https://authentication.aecfafrica.org/api/',
	confirmEmailCallbackUrl: 'https://ess.aecfafrica.org/confirm-email',
	resetPasswordCallbackUrl: 'https://ess.aecfafrica.org/reset-password',
	allowedUrls: ['https://ess.aecfafrica.org/api', 'https://authentication.aecfafrica.org/api'],
	tokenIssuer: 'https://identity.aecfafrica.org'
};
