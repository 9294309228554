import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
	issuer: environment.tokenIssuer,
	clientId: 'AngularPortalClient',
	dummyClientSecret: 'AngularPortalClientSecret',
	oidc: false,
	scope: 'openid profile Permissions AuthAPI EmployeePortalApi',
	requireHttps: false
};
