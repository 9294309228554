import { Component, OnInit, OnDestroy, AfterViewInit, Renderer2 } from '@angular/core';
import { LayoutService } from './services/layout.service';
import { EmployeeService } from './services/employee.service';
import { DimensionService } from './services/dimension.service';
import { ReportService } from './services/report.service';

@Component({
	selector: 'app-core',
	templateUrl: './core.component.html'
})
export class CoreComponent implements OnInit, OnDestroy, AfterViewInit {
	documentClickListener: Function;

	constructor(
		public renderer: Renderer2,
		private employeeService: EmployeeService,
		private dimensionService: DimensionService,
		private reportService: ReportService,
		public layoutService: LayoutService
	) {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		// hides the overlay menu and top menu if outside is clicked
		this.documentClickListener = this.renderer.listen('body', 'click', () => {
			if (!this.layoutService.isDesktop()) {
				if (!this.layoutService.menuClick) {
					this.layoutService.menuActiveMobile = false;
				}

				if (!this.layoutService.topMenuButtonClick) {
					this.layoutService.hideTopMenu();
				}
			}

			this.layoutService.menuClick = false;
			this.layoutService.topMenuButtonClick = false;
		});
	}

	ngOnDestroy() {
		if (this.documentClickListener) {
			this.documentClickListener();
		}
	}
}
