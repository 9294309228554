import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { InfoMessage } from '../account/models/info-message.model';

@Injectable()
export class ProfileService {
	constructor(private httpClient: HttpClient, private configService: ConfigService) {}

	confirmEmail(email: string, token: string) {
		return this.httpClient.get<InfoMessage>(this.configService.authApiBaseUrl.concat('User/ConfirmEmail'), {
			params: {
				email: email,
				token: token
			}
		});
	}

	sendForgotPasswordLink(email: string) {
		return this.httpClient.post<InfoMessage>(this.configService.authApiBaseUrl.concat('User/ForgotPassword'), {
			email: email,
			callbackUrl: this.configService.resetPasswordCallbackUrl
		});
	}

	resetPassword(resetPasswordDto: any) {
		return this.httpClient.post<InfoMessage>(
			this.configService.authApiBaseUrl.concat('User/ResetPassword'),
			resetPasswordDto
		);
	}

	changePassword(changePasswordDto: any) {
		return this.httpClient.post<InfoMessage>(
			this.configService.authApiBaseUrl.concat('User/ChangePassword'),
			changePasswordDto
		);
	}
}
