import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {
	changePasswordForm: FormGroup;
	showForm: boolean = true;

	confirmPassword: string;
	passwordMatch: boolean;

	messages: Message[] = [];

	constructor(
		private profileService: ProfileService,
		private authService: AuthenticationService,
		private router: Router
	) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.changePasswordForm = new FormGroup({
			email: new FormControl(this.authService.getUserEmail()),
			oldPassword: new FormControl(null, Validators.required),
			newPassword: new FormControl(null, Validators.required)
		});
	}

	onConfirmPassword() {
		const password = this.changePasswordForm.get('newPassword').value;
		this.passwordMatch = this.confirmPassword === password;
	}

	onSubmit() {
		this.showForm = false;
		let form = this.changePasswordForm.value;
		this.profileService.changePassword(form).subscribe(
			(item) => {
				this.showForm = true;
				this.setMessage({
					severity: 'info',
					detail: item.infoMessage
				});

				this.changePasswordForm.reset();
				this.changePasswordForm.get('email').setValue(this.authService.getUserEmail());
			},
			(error) => {
				this.showForm = true;
				if ('errorMessage' in error.error) {
					this.setMessage({
						severity: 'error',
						detail: error.error['errorMessage']
					});
				}
			}
		);
	}
}
