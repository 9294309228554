import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CoreComponent } from './core.component';
import { AuthGuardService } from '../guards/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RoleGuardService } from '../guards/role-guard.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ProfileEditComponent } from './dashboard/profile-edit/profile-edit.component';
import { CountryGuardService } from '../guards/country-guard.service';

const routes: Routes = [
	{
		path: 'core',
		component: CoreComponent,
		canActivate: [ AuthGuardService, CountryGuardService, RoleGuardService ],
		children: [
			{ path: '', pathMatch: 'full', redirectTo: 'dashboard' },
			{ path: 'dashboard', component: DashboardComponent },
			{ path: 'edit-profile', component: ProfileEditComponent },
			{ path: 'change-password', component: ChangePasswordComponent },
			{
				path: 'leave-applications',
				loadChildren: './leave-applications/leave-application.module#LeaveApplicationModule'
			},
			{ path: 'timesheets', loadChildren: './timesheets/timesheets.module#TimesheetModule' },
			{ path: 'transport', loadChildren: './transport/transport.module#TransportModule' },
			{ path: 'accidents', loadChildren: './accidents/accident.module#AccidentModule' },
			{
				path: 'training-applications',
				loadChildren: './training-applications/training-application.module#TrainingApplicationModule'
			},
			{ path: 'toil', loadChildren: './toil/toil.module#ToilModule' },
			{
				path: 'employee-requisitions',
				loadChildren: './employee-requisitions/employee-requisitions.module#EmployeeRequisitionModule'
			},
			{ path: 'grievances', loadChildren: './grievances/grievances.module#GrievanceModule' },
			{ path: 'pip', loadChildren: './pip/pip.module#PipModule' },
			{ path: 'ppe', loadChildren: './ppe/ppe.module#PpeModule' },
			{ path: 'workorder', loadChildren: './workorder/workorder.module#WorkorderModule' },
			{ path: 'appraisals', loadChildren: './appraisals/appraisals.module#AppraisalsModule' },
			{ path: 'probation', loadChildren: './probation-reviews/probation-reviews.module#ProbationReviewsModule' },
			{ path: 'purchase', loadChildren: './purchase/purchase.module#PurchaseModule' },
			{ path: 'funds', loadChildren: './funds/funds.module#FundsModule' },
			{ path: 'graduate-evaluation', loadChildren: './graduate-trainee/graduate-trainee.module#GraduateTraineeModule' },
			{ path: 'reports', loadChildren: './reports/reports.module#ReportsModule' }
		]
	}
];

@NgModule({
	imports: [ RouterModule.forChild(routes) ],
	exports: [ RouterModule ],
	declarations: []
})
export class CoreRoutingModule {}
