import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ConfigService } from 'src/app/services/config.service';

@Component({
	selector: 'app-no-country',
	templateUrl: './no-country.component.html'
})
export class NoCountryComponent implements OnInit {
	organizationName: string;
	constructor(
		private authService: AuthenticationService,
		private router: Router,
		private configService: ConfigService
	) {}

	ngOnInit() {
		this.organizationName = this.configService.organizationName;
	}

	logout() {
		this.authService.logout();
		this.router.navigate([ '/login' ]);
	}
}
