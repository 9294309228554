import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Document } from '../reports/models/document.model';

@Injectable()
export class ReportService {
	payrollPeriods: SelectItem[];
	documentList: Document[];

	constructor(
		private httpClient: HttpClient,
		private authService: AuthenticationService,
		private configService: ConfigService
	) {
		this.getPayrollPeriods().subscribe((items) => {
			this.payrollPeriods = items;
		});
		this.getEmployeeDocuments().subscribe((items) => {
			this.documentList = items;
		});
	}

	getPurchaseRequisition1Report(requisitionNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/PurchaseRequisition1'), {
			responseType: 'blob' as 'json'
		});
	}

	getPurchaseRequisition2Report(requisitionNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/PurchaseRequisition2'), {
			params: {
				requisitionNo: requisitionNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getStaffAdvanceSurrenderReport(surrenderNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/StaffAdvanceSurrender'), {
			params: {
				surrenderNo: surrenderNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getStaffAdvanceReport(advanceNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/StaffAdvance'), {
			params: {
				advanceNo: advanceNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getStaffClaimReport(claimNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/StaffClaim'), {
			params: {
				claimNo: claimNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getPostedStaffClaimReport(claimNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/PostedStaffClaim'), {
			params: {
				claimNo: claimNo
			},
			responseType: 'blob' as 'json'
		});
	}
	getTravelAdvanceReport(advanceNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/TravelAdvance'), {
			params: {
				advanceNo: advanceNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getTravelAdvanceSurrenderReport(surrenderNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/TravelAdvanceSurrender'), {
			params: {
				surrenderNo: surrenderNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getQuarterlyReviewReport(reviewNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/QuarterlyReview'), {
			params: {
				reviewNo: reviewNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getQuarterlyReviewTargetsReport(reviewNo: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/QuarterlyReviewTargets'), {
			params: {
				reviewNo: reviewNo
			},
			responseType: 'blob' as 'json'
		});
	}

	getP9Report(year: number) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/P9Report'), {
			params: {
				employeeNo: this.authService.getEmployeeNo(),
				year: year.toString()
			},
			responseType: 'blob' as 'json'
		});
	}

	getPayslip(selectedPeriod: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/Payslip'),
			{
				employeeNo: this.authService.getEmployeeNo(),
				selectedPeriod: selectedPeriod
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getAppraisalMidYearReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/AppraisalMidYear'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getAppraisalEndYearReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/AppraisalEndYear'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getLeaveStatement() {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/LeaveStatement'), {
			params: {
				employeeNo: this.authService.getEmployeeNo()
			},
			responseType: 'blob' as 'json'
		});
	}

	getPIPStatement(no:string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/PipStatement'), {
			params: {
				pipNo: no
			},
			responseType: 'blob' as 'json'
		});
	}

	getAppraisalSummaryReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/AppraisalSummary'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getTargetReviewReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/AppraisalTargetReview'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getProbationReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/ProbationReport'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getEmployeeExitReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/EmployeeExitReport'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getExitInterviewReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/ExitInterviewReport'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getAccidentReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/AccidentReport'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	
	getWorkOrderReport(key: string) {
		return this.httpClient.get<Blob>(
			this.configService.apiBaseUrl.concat('Report/WorkOrderReport'),
			{
				params: {
					docNo:key
				},
				responseType: 'blob' as 'json'
			}
		);
	}

	getTrainingApplicationReport(key: string) {
		return this.httpClient.post<Blob>(
			this.configService.apiBaseUrl.concat('Report/TrainingApplicationReport'),
			{
				key: key
			},
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	getPayrollPeriods() {
		return this.httpClient.get<SelectItem[]>(this.configService.apiBaseUrl.concat('Report/PayrollPeriod'));
	}

	getEmployeeDocuments() {
		return this.httpClient.get<Document[]>(this.configService.authApiBaseUrl.concat('Document/Filter'), {
			params: {
				audience: 'employee'
			}
		});
	}

	downloadDocument(docId: number) {
		return this.httpClient.get<Blob>(
			this.configService.authApiBaseUrl.concat('Document/Download/', docId.toString()),
			{
				responseType: 'blob' as 'json'
			}
		);
	}

	downloadDutyLeaveReport(no: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/DutyLeave'), {
			params: {
				no: no
			},
			responseType: 'blob' as 'json'
		});
	}

	downloadGTReportReport(no: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/GraduateTrainee'), {
			params: {
				no: no
			},
			responseType: 'blob' as 'json'
		});
	}

	downloadPpeReport(key: string) {
		return this.httpClient.get<Blob>(this.configService.apiBaseUrl.concat('Report/PPEReport'), {
			params: {
				key: key
			},
			responseType: 'blob' as 'json'
		});
	}

}
