import { Component, OnInit } from '@angular/core';
import { Message } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { EmployeeProfile } from '../models/employee-profile.model';
import { EmployeeService } from '../services/employee.service';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
	organizationName = environment.organizationName;
	applicationName = environment.applicationName;

	constructor(private employeeService: EmployeeService) {}

	approvedEmployee: EmployeeProfile;

	messages: Message[] = [];

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	getFullName() {
		return [
			this.approvedEmployee.first_Name,
			this.approvedEmployee.middle_Name,
			this.approvedEmployee.last_Name
		].join(' ');
	}

	ngOnInit() {
		this.approvedEmployee = history.state.data;
		if (this.approvedEmployee === undefined) {
			this.employeeService.getEmployeeProfile().subscribe(
				(employee) => {
					this.approvedEmployee = employee;	
				},				
				(error) => {
					if ('errorMessage' in error.error) {
						this.setMessage({
							severity: 'error',
							detail: error.error['errorMessage']
						});
					}
				}
			);
		}
	}

	showView() {
		return this.approvedEmployee !== undefined;
	}
}
