import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from 'src/app/services/config.service';
import { SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { EmployeeProfile } from '../models/employee-profile.model';

@Injectable()
export class EmployeeService {
	employeeListManager: SelectItem[] = [];
	employeesManagerLoaded: boolean;
	constructor(
		private httpClient: HttpClient,
		private authService: AuthenticationService,
		private configService: ConfigService
	) {
		this.getEmployees().subscribe((items) => {

			const emps = items.filter((item) => {
				return item.value.toString() !== this.authService.getEmployeeNo();
			});
			this.setEmployeeList(emps);
		},err=>{
			console.log(err)
		});

		this.getEmployeesManager().subscribe((items) => {
			const emps = items.filter((item) => {
				return item.value.managerNo.toString()=== this.authService.getEmployeeNo();
			});
			
			this.setEmployeeListManager(emps);
		});


		this.getReligions().subscribe((items) => {
			this.religions = items;
		});
	}

	employeeProfile: EmployeeProfile;

	employeeList: SelectItem[] = [];
	employeesLoaded = false;

	religions: SelectItem[];

	getReligions() {
		return this.httpClient.get<SelectItem[]>(this.configService.apiBaseUrl.concat('LeaveApplication/Religion'));
	}

	getEmployees() {
		return this.httpClient.get<SelectItem[]>(this.configService.apiBaseUrl.concat('LeaveApplication/Reliever'));
	}
	getEmployeesManager() {
		return this.httpClient.get<any[]>(this.configService.apiBaseUrl.concat('LeaveApplication/RelieverManager'));
	}

	setEmployeeList(employees: SelectItem[]) {
		this.employeeList.length = 0;
		this.employeeList.push(...employees);
		this.employeesLoaded = true;
	}

	setEmployeeListManager(employees: any[]) {
		this.employeeListManager.length = 0;
		this.employeeListManager.push(...employees);
		this.employeesManagerLoaded = true;
	}

	getEmployeeProfile() {
		return this.httpClient.get<EmployeeProfile>(
			this.configService.apiBaseUrl.concat('LeaveApplication/EmployeeProfile'),
			{
				params: {
					employeeNo: this.authService.getEmployeeNo()
				}
			}
		);
	}

	updateEmployeeProfile(employee: EmployeeProfile) {
		return this.httpClient.post<EmployeeProfile>(
			this.configService.apiBaseUrl.concat('LeaveApplication/UpdateEmployeeProfile'),
			employee
		);
	}
}
