import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
	MessageModule,
	InputTextModule,
	MessagesModule,
	ToolbarModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	ConfirmDialogModule,
	DialogModule,
	CalendarModule,
	DropdownModule,
	InputTextareaModule,
	MultiSelectModule,
	ToggleButtonModule,
	AutoCompleteModule,
	SpinnerModule,
	FieldsetModule,
	PanelModule,
	FileUploadModule,
	TabViewModule,
	AccordionModule,
	StepsModule,
	TabMenuModule,
	InputSwitchModule
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/components/dynamicdialog/dynamicdialog';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { ApprovalService } from './services/approvals.service';
import { ApprovalsComponent } from './approvals/approvals.component';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MessageModule,
		InputTextModule,
		MessagesModule,
		ToolbarModule,
		ProgressBarModule,
		TableModule,
		ProgressSpinnerModule,
		ConfirmDialogModule,
		DialogModule,
		CalendarModule,
		DropdownModule,
		InputTextareaModule,
		MultiSelectModule,
		ToggleButtonModule,
		AutoCompleteModule,
		DynamicDialogModule,
		SpinnerModule,
		FieldsetModule,
		PanelModule,
		FileUploadModule,
		TabViewModule,
		AccordionModule,
		StepsModule,
		TabMenuModule,
		InputSwitchModule
	],
	declarations: [ DocumentUploadComponent, ApprovalsComponent ],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MessageModule,
		InputTextModule,
		MessagesModule,
		ToolbarModule,
		ProgressBarModule,
		TableModule,
		ProgressSpinnerModule,
		ConfirmDialogModule,
		DialogModule,
		CalendarModule,
		DropdownModule,
		InputTextareaModule,
		MultiSelectModule,
		ToggleButtonModule,
		AutoCompleteModule,
		DynamicDialogModule,
		SpinnerModule,
		FieldsetModule,
		PanelModule,
		FileUploadModule,
		TabViewModule,
		AccordionModule,
		StepsModule,
		TabMenuModule,
		InputSwitchModule,
		DocumentUploadComponent,
		ApprovalsComponent
	],
	providers: [ ApprovalService ],
	entryComponents: [ DocumentUploadComponent, ApprovalsComponent ]
})
export class SharedModule {}
