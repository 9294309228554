import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Message, SelectItem } from 'primeng/api';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
	selector: 'app-choose-country',
	templateUrl: './choose-country.component.html'
})
export class ChooseCountryComponent implements OnInit {
	countryForm: FormGroup;
	showForm: boolean = true;

	countries: SelectItem[] = [
		{
			label: 'Kenya',
			value: 'Kenya'
		},
		{
			label: 'USA',
			value: 'USA'
		}
	];

	messages: Message[] = [];

	constructor(private authService: AuthenticationService, private router: Router) {}

	clearMessages() {
		this.messages.length = 0;
	}

	setMessage(msg: Message) {
		this.clearMessages();
		this.messages.push(msg);
	}

	ngOnInit() {
		this.countryForm = new FormGroup({
			country: new FormControl(null, Validators.required)
		});
	}

	onSelectCountry() {
		this.showForm = false;
		const country = this.countryForm.get('country').value;
		this.authService.setCountry(country);

		this.router.navigate([ '/core' ]);
	}
}
